import {
  ThemeProvider as MuiThemeProvider,
  Theme,
  ThemeOptions,
  createTheme
} from '@mui/material';
import React from 'react';
import { CssVariableOverrider } from './CssVariableOverrider';

import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';

export const mainTheme = createTheme({
  typography: {
    body1: {
      fontSize: '16px'
    },
    h1: {
      color: '#000',
      fontSize: '48px',
      lineHeight: '60px',
      fontWeight: '400',
      letterSpacing: 0
    },
    h2: {
      fontSize: '32px',
      lineHeight: '38px',
      fontWeight: '500',
      letterSpacing: 0
    },
    h3: {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: '400',
      letterSpacing: 0
    },
    h4: {
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: '400',
      letterSpacing: 0
    },

    h6: {
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: '500',
      letterSpacing: 0
    }
  },
  palette: {
    primary: {
      main: '#ff6b6b',
      light: '#FFABA6',
      dark: '#D95B5B',
      contrastText: '#fff'
    },
    secondary: {
      main: '#1a535c'
    }
  },
  components: {
    // We override the styles here temporary to align it to the falcon theme
    MuiTableCell: {
      styleOverrides: {
        head: ({ theme }) => ({
          fontSize: theme.typography.subtitle2.fontSize,
          textTransForm: 'capitalize'
        }),
        body: ({ theme }) => ({
          fontSize: theme.typography.subtitle2.fontSize
        })
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 4
        }
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
        square: true
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          border: 'none'
        }
      }
    },
    MuiAutocomplete: {
      defaultProps: {
        slotProps: {
          paper: {
            elevation: 6
          }
        }
      },
      styleOverrides: {
        // Get the original size back, since IconButton is overriden bit we want to keep the size here.
        popupIndicator: {
          height: 28,
          width: 28
        },
        clearIndicator: {
          height: 28,
          width: 28
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({ theme }) => ({
          paddingRight: theme.spacing(3),
          paddingLeft: theme.spacing(3),
          paddingBottom: theme.spacing(2)
        })
      }
    },

    MuiTooltip: {
      defaultProps: {
        arrow: true,
        placement: 'top'
      },
      styleOverrides: {
        tooltip: {
          fontSize: '12px',
          fontWeight: 400,
          backgroundColor: '#616161E5'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 0,
          svg: {
            color: 'inherit',
            transition: 'none'
          }
        },
        outlinedPrimary: ({ theme }) => ({
          ':hover': {
            color: '#fff',
            backgroundColor: theme.palette.primary.main
          },
          '&.active': {
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
          }
        })
      }
    },
    MuiIconButton: {
      styleOverrides: {
        sizeSmall: { height: '30.75px', width: '30.75px', padding: '4px 5px' },
        sizeMedium: { height: '36.5px', width: '36.5px', padding: '6px 8px' },
        sizeLarge: { height: '42.25px', width: '42.25px', padding: '8px 11px' },
        root: {
          svg: {
            color: 'inherit'
          }
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          svg: {
            color: 'inherit'
          }
        }
      }
    },
    MuiMenu: {
      defaultProps: {
        /**
         * Currently if we lock scrolling, the page jumps
         * As soon as the following rule gets removed from custom.scss, we can enable this again.
         *   html { width: 100vw; overflow-x: hidden; }
         */
        disableScrollLock: true
      }
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          textTransform: 'none',
          fontSize: theme.typography.body1.fontSize
        })
      }
    }
  }
});

const inCosmeticsTheme: ThemeOptions = {
  palette: {
    primary: {
      main: '#784A98',
      light: '#E7E0EB',
      dark: '#54346a'
    }
  }
};

export const getIncosmeticsTheme = (theme: Theme) =>
  createTheme(theme, inCosmeticsTheme);

/**
 * ThemeProvider which should be always used.
 * Injects the CSS variables, but not on root level.
 */
export const ThemeProvider: React.FC<
  React.PropsWithChildren<{
    theme?: Partial<Theme> | ((outerTheme: Theme) => Theme);
  }>
> = ({ children, theme }) => (
  <MuiThemeProvider theme={theme ?? mainTheme}>
    {theme ? <CssVariableOverrider>{children}</CssVariableOverrider> : children}
  </MuiThemeProvider>
);

/**
 * Helper for places where the Theme context does not exist.
 */
export const withThemeContext = (children: JSX.Element) => (
  <ThemeProvider theme={mainTheme}>{children}</ThemeProvider>
);
