import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';

import { AuthenticationProvider } from 'auth/AuthenticationProvider';
import { LaunchDarklyProvider } from 'context/LaunchDarklyProvider';
import { UserProvider } from 'context/User';
import i18n from 'i18n';
import { DropdownProvider } from 'layouts/DropdownProvider';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from 'theme/Theme';
import LayoutLegacy from './layouts/Layout';
import { IS_UXBEAUTY } from 'components/constants/Constants';
import { Suspense, lazy } from 'react';
import { ToastContainer } from 'react-toastify';
//maybe could also be on a lower level or imported lazily,
//but seems on importing inside the file the styles doesn't get applied
import 'react-advanced-cropper/dist/style.css';
import 'react-multi-carousel/lib/styles.css';
const LayoutUxBeauty = lazy(() => import('supplier-portals/uxbeauty/Layout'));

const App = () => (
  <I18nextProvider i18n={i18n}>
    <ThemeProvider>
      <AuthenticationProvider>
        <DropdownProvider>
          <LaunchDarklyProvider>
            <UserProvider>
              {IS_UXBEAUTY ? (
                <Suspense>
                  <LayoutUxBeauty />
                </Suspense>
              ) : (
                // TODO on the long term we build a new layout for Covalo based on the new insights from UX Beauty
                <LayoutLegacy />
              )}
              <ToastContainer
                position="bottom-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                toastClassName={IS_UXBEAUTY ? 'toast-container-uxbeauty' : ''}
              />
            </UserProvider>
          </LaunchDarklyProvider>
        </DropdownProvider>
      </AuthenticationProvider>
    </ThemeProvider>
  </I18nextProvider>
);

export default App;
