import * as Sentry from '@sentry/react';
import { captureException } from '@sentry/react';
import {
  useGetAllCompanies,
  useGetAllEvents,
  useGetLegacySettings,
  useGetUserSettings
} from 'api/services/UserServiceTS';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import piwik from 'piwik/piwik';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import runtimeContext from './runtime-context';
import {
  SupplierPortalUserStatus,
  UserStateContextType
} from 'components/constants/Constants';
import { useGetBrandPortalSettings } from 'api/services/EventsService';
import { LoggedInUser, UserState } from 'types/User';
import {
  BrandPortalPermissions,
  EventPermissionLevel,
  UserSettings
} from 'portals/types';

const identifyAndUpdateHubSpotContact = (
  emailAddress: string | undefined,
  properties: any,
  viewPath: string
) => {
  try {
    // default value for properties
    properties = typeof properties !== 'undefined' ? properties : {};

    properties = {
      ...properties,
      email: emailAddress
    };
    const _hsq = runtimeContext.setGlobal(
      '_hsq',
      runtimeContext.getGlobal('_hsq') || []
    );

    _hsq.push(['identify', properties]);
    _hsq.push(['setPath', viewPath]);
    _hsq.push(['trackPageView']);
  } catch (error) {
    // ignore
  }
};

/**
 * @deprecated use useUser instead using the context directly!
 */
export const UserContext = createContext<LoggedInUser | undefined>(undefined);

const UserStateContext = createContext<UserState>({
  type: UserStateContextType.LOADING
});

export const UserProvider: React.FC<React.PropsWithChildren> = ({
  children
}) => {
  const auth = useAuth();
  const ldClient = useLDClient();

  const [user, setUser] = useState<LoggedInUser>();

  const [settingsLegacy, loadUserSettingsLegacy] = useGetLegacySettings();

  const [userSettings, loadUserSettings] = useGetUserSettings();
  //This is just for chemberry support user to have all events
  const [allEventsData, loadAllEvents] = useGetAllEvents();

  const [brandPortalPermissions, loadBrandPortalPermissions] =
    useGetBrandPortalSettings();

  const [assignedCompanies, loadAssignedCompanies] = useGetAllCompanies();

  const updateUser = (user: LoggedInUser) => {
    setUser(user);
  };

  useEffect(() => {
    if (!user?.id) return;

    !assignedCompanies.loading &&
      assignedCompanies.response?.status !== 200 &&
      !assignedCompanies.error &&
      loadAssignedCompanies().then(({ data }) => {
        if (data.page.total_pages > 50) {
          // Ignore. It's a support user who has access to all companies
        } else if (data.page.total_pages > 1) {
          captureException(
            'Non-support user has more companies than possible to be fetched in one page. Needs investigation!'
          );
        } else {
          // This user has access to one or a few companies. We add them to the user context.
          setUser(user => ({
            ...user,
            assignedCompanies: data.content.map(company => ({
              key: company.key,
              name: company.name
            }))
          }));
        }
      });
  }, [
    assignedCompanies.error,
    assignedCompanies.loading,
    assignedCompanies.response?.status,
    loadAssignedCompanies,
    user?.id
  ]);

  useEffect(() => {
    //we can get rid of this check if ew start using useUserState everywhere instead of userContext
    if (!auth.isAuthenticated) setUser(undefined);
    if (auth.isAuthenticated && auth.user) {
      if (user !== undefined) {
        return;
      }
      const keycloakUser = auth.user;
      //this piwik login action is a bit strange because if the user is logged in and reloads the browser it will trigger.
      // should trigger just once he loggs in
      piwik.push(['setUserId', keycloakUser.profile.email ?? '']);

      identifyAndUpdateHubSpotContact(keycloakUser.profile.email, '', '/');

      const baseUser: LoggedInUser = {
        id: keycloakUser.profile.sub,
        username: keycloakUser.profile.preferred_username,
        firstName: keycloakUser.profile.given_name,
        lastName: keycloakUser.profile.family_name,

        // Needs some special config in keycloak on for all environments
        // https://<keycloak-url>/auth/admin/master/console/#/realms/chemberry/client-scopes/<roles-id>/mappers/<realm-roles-id>
        // as described here: https://github.com/authts/react-oidc-context/issues/872
        roles: (keycloakUser.profile as any).realm_access.roles,
        updateUser: updateUser
      };

      !settingsLegacy.data &&
        !settingsLegacy.error &&
        !settingsLegacy.loading &&
        loadUserSettingsLegacy()
          .then(({ data }) => {
            setUser(user => ({
              ...user,
              ...baseUser,
              id: data.userId,
              department: data.department,
              permissions: data.permissions
            }));
          })
          .catch(() => {
            setUser(user => ({ ...user, ...baseUser }));
          });

      !userSettings.data &&
        !userSettings.error &&
        !userSettings.loading &&
        loadUserSettings().then(({ data }) => {
          setUser(user => ({
            ...user,
            ...baseUser,
            supplierPortalUserStatus: data
              ? mapSettingsToSupplierPortalUserStatus(data)
              : undefined,
            supplierPortalUserPermissions: data.permissions
          }));
        });

      !brandPortalPermissions.data &&
        !brandPortalPermissions.error &&
        !brandPortalPermissions.loading &&
        !allEventsData.data &&
        !allEventsData.error &&
        !allEventsData.loading &&
        //This is just for chemberry support user to have all events
        (baseUser?.roles?.includes('chemberry-support')
          ? loadAllEvents().then(({ data }) => {
              setUser(user => ({
                ...user,
                ...baseUser,
                brandPortalEvent: data.content
                  ?.filter(event => event.key && event.type === 'BRAND_PORTAL')
                  .map(event => {
                    return {
                      ...event,
                      permissions: Object.entries(
                        BrandPortalPermissions
                      ).reduce(
                        (acc, [, value]) => ({
                          ...acc,
                          [value]:
                            value === BrandPortalPermissions.SEARCH
                              ? EventPermissionLevel.VIEW
                              : EventPermissionLevel.MANAGE
                        }),
                        {}
                      )
                    };
                  })
              }));
            })
          : loadBrandPortalPermissions().then(({ data }) => {
              setUser(user => ({
                ...user,
                ...baseUser,
                brandPortalEvent: data?.content
                  ?.filter(event => event.event.key)
                  .map(event => ({
                    ...event.event,
                    permissions: event.permissions
                  }))
              }));
            }));
    }
  }, [
    allEventsData,
    auth,
    brandPortalPermissions,
    loadAllEvents,
    loadBrandPortalPermissions,
    loadUserSettings,
    loadUserSettingsLegacy,
    settingsLegacy,
    user,
    userSettings
  ]);

  const userState = useMemo<UserState>(() => {
    if (!auth.isLoading && auth.isAuthenticated === false) {
      return { type: UserStateContextType.NOT_AUTHENTICATED };
    } else if (
      user &&
      auth.isAuthenticated === true &&
      !auth.isLoading &&
      (!!settingsLegacy.data || !!settingsLegacy.error) &&
      //userSettings can also be empty string (? dunno why be sends it like this)
      //but we cant just check if its defined or not
      (userSettings.data !== undefined || !!userSettings.error) &&
      (!!brandPortalPermissions.data ||
        !!brandPortalPermissions.error ||
        !!allEventsData.data ||
        !!allEventsData.error)
    ) {
      return { type: UserStateContextType.AUTHENTICATED, user };
    } else {
      return { type: UserStateContextType.LOADING };
    }
  }, [
    allEventsData.data,
    allEventsData.error,
    auth.isAuthenticated,
    auth.isLoading,
    brandPortalPermissions.data,
    brandPortalPermissions.error,
    settingsLegacy.data,
    settingsLegacy.error,
    user,
    userSettings.data,
    userSettings.error
  ]);

  useEffect(() => {
    if (user) {
      Sentry.setUser({
        id: user.id,
        username: user.username
      });
    } else {
      Sentry.setUser(null);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      ldClient?.identify({
        email: user.email,
        key: user.id,
        username: user.username,
        name: [user.firstName, user.lastName].join(' '),
        kind: 'user'
      });
    }
  }, [user, ldClient]);
  return (
    <UserStateContext.Provider value={userState}>
      <UserContext.Provider value={user}>{children}</UserContext.Provider>
    </UserStateContext.Provider>
  );
};

/**
 * @returns the user state, which switches to AUTHENTICATED once the user is loaded with all authentication relevant data.
 */
export const useUserState = () => useContext(UserStateContext);

/**
 * @deprecated use useUserState to ensure all relevant user data is available!
 */
export const useUser = () => useContext(UserContext);

const mapSettingsToSupplierPortalUserStatus = (
  settings: UserSettings
): SupplierPortalUserStatus => {
  if (settings.event_user === undefined) {
    return SupplierPortalUserStatus.NOT_SIGNED_UP;
  } else if (settings.event_user.status === 'VERIFIED') {
    return SupplierPortalUserStatus.VERIFIED;
  } else if (settings.event_user.status === 'BLOCKED') {
    return SupplierPortalUserStatus.BLOCKED;
  }
  return SupplierPortalUserStatus.AWAITING_VERIFICATION;
};
