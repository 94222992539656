import classNames from 'classnames';
import { NotificationsProvider } from 'context/Notifications';
import { lazy, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useUserState } from '../context/User';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import { tss } from 'tss-react/mui';
import { UserStateContextType } from 'components/constants/Constants';
const Footer = lazy(() => import('components/common/Footer/Footer'));

const MainLayout: React.FC<{
  component: JSX.Element;
  isPublic?: boolean;
  hasWhiteBackground?: boolean;
  noVerticalPadding?: boolean;
  preventDoubleScrollbars?: boolean;
}> = ({
  component,
  isPublic,
  hasWhiteBackground,
  noVerticalPadding,
  preventDoubleScrollbars
}) => {
  const { hash, pathname } = useLocation();
  const userState = useUserState();
  const user =
    userState.type === UserStateContextType.AUTHENTICATED
      ? userState.user
      : undefined;
  const { classes } = useStyles();

  const updateProductsContext = (data: any) => {
    setProductsContext({ ...productsContext, data });
  };

  const resetProductContext = () => {
    setProductsContext({ ...productsContext, data: null });
  };

  const [productsContext, setProductsContext] = useState<any>({
    data: null,
    updateProducts: updateProductsContext,
    resetProductContext: resetProductContext
  });

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (productsContext.data !== null) {
    if (
      pathname === `/products/${productsContext.data?.type.toLowerCase()}` ||
      pathname.includes('/product/')
    ) {
      //  keep context
    } else {
      resetProductContext();
    }
  }

  const renderWithNavbar = () => (
    <>
      <NavigationBar withoutMargins={preventDoubleScrollbars ? true : false} />
      {preventDoubleScrollbars ? (
        component
      ) : (
        <div className={!noVerticalPadding ? `px-3` : undefined}>
          {component}
        </div>
      )}
    </>
  );

  return (
    <>
      <div
        className={classNames([
          { 'container-fluid': !preventDoubleScrollbars },
          { 'bg-white': hasWhiteBackground }
        ])}
      >
        <div
          className={preventDoubleScrollbars ? classes.adminContent : 'content'}
        >
          {/* //we still need the notificationprovider for public routes if we have a user  */}
          {isPublic && !user
            ? renderWithNavbar()
            : user && (
                <NotificationsProvider>
                  {renderWithNavbar()}
                </NotificationsProvider>
              )}
        </div>
      </div>
      {!preventDoubleScrollbars && <Footer />}
    </>
  );
};

export default MainLayout;

const useStyles = tss.create({
  adminContent: {
    minHeight: '100vh',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
  }
});
