import { initMSW } from 'helpers/initMSW';
import { initSentry } from 'helpers/initSentry';
import { createRoot } from 'react-dom/client';
import App from './App';
import { UserManager } from 'oidc-client-ts';
import {
  OAUTH_AUTHORITY,
  OAUTH_CLIENT_ID,
  OAUTH_REDIRECT_URI,
  OAUTH_SILENT_REDIRECT_PATHNAME
} from 'auth/AuthenticationProvider';
import { IS_UXBEAUTY } from 'components/constants/Constants';
import { initHubspot } from 'helpers/initHubspot';

if (!IS_UXBEAUTY) initHubspot();

initSentry();
initMSW();

if (window.location.pathname === OAUTH_SILENT_REDIRECT_PATHNAME) {
  const mgr = new UserManager({
    client_id: OAUTH_CLIENT_ID,
    authority: OAUTH_AUTHORITY,
    redirect_uri: OAUTH_REDIRECT_URI
  });
  mgr.signinCallback();
} else {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(document.getElementById('main')!);
  root.render(<App />);
}
