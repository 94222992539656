import { Button, Menu, MenuItem, Zoom, useTheme } from '@mui/material';

import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

import React from 'react';
import { SingleNavBarItem } from './SingleNavBarItem';
import { useBreakpoints } from 'hooks/useBreakpoints';

interface Props {
  brandPortalRoutes: {
    label: string;
    key: string;
  }[];
  activeLink: string;
  closeOuterMenu?: () => void;
}
export const IngredientsDropdown: React.FC<Props> = ({
  activeLink,
  brandPortalRoutes,
  closeOuterMenu
}) => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    //needed just on mobile
    closeOuterMenu?.();
  };
  const { width } = useBreakpoints();
  const theme = useTheme();
  const navOpen = Boolean(anchorElNav);
  const isMobile = width < theme.breakpoints.values.lg;

  return (
    <>
      <MenuItem
        onClick={handleOpenNavMenu}
        sx={theme => ({
          color: theme.palette.text.primary,
          textTransform: 'capitalize',
          paddingRight: 0,
          display: { xs: 'flex', lg: 'none' },
          svg: { color: 'inherit' },
          [`&:hover,active`]: {
            background: 'inherit',
            color: theme.palette.primary.main
          }
        })}
      >
        Ingredients
        <NavigateNextOutlinedIcon fontSize="small" />
      </MenuItem>
      <Button
        onClick={handleOpenNavMenu}
        variant="text"
        sx={theme => ({
          color: theme.palette.text.primary,
          textTransform: 'capitalize',
          marginRight: -1,
          display: { xs: 'none', lg: 'flex' },
          fontWeight: 400
        })}
      >
        Ingredients
        <ExpandMoreOutlinedIcon fontSize="small" />
      </Button>
      {anchorElNav && (
        <Menu
          anchorEl={anchorElNav}
          open={navOpen}
          onClick={handleCloseNavMenu}
          onClose={handleCloseNavMenu}
          MenuListProps={{
            dense: true
          }}
          anchorOrigin={{
            vertical: isMobile ? 'top' : 'bottom',
            horizontal: isMobile ? 'right' : 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          TransitionComponent={Zoom}
        >
          {[
            { label: 'Covalo Index', path: '/ingredients/search' },
            ...brandPortalRoutes.map(event => ({
              ...event,
              path: '/search/ingredients/' + event.key
            }))
          ].map(item => (
            <SingleNavBarItem
              type="menuitem"
              handleMenuClose={handleCloseNavMenu}
              item={item}
              activeLink={activeLink}
              key={item.label}
            />
          ))}
        </Menu>
      )}
    </>
  );
};
