import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { getNotifications } from 'api/services/NotificationsService';
import { NoNotifications } from 'components/notification/NoNotifications';
import Notification from 'components/notification/Notification';
import { NotificationsContext } from 'context/Notifications';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export function removeSomeNotifications(inputArray: any) {
  return inputArray.filter(
    (obj: any) =>
      obj.action !== 'USER_REGISTRATION' &&
      obj.action !== 'PRODUCT_UPDATED' &&
      obj.action !== 'COMPANY_UPDATED'
  );
}
const Notifications = () => {
  const [noNotifications, setNoNotifications] = useState(false);
  const [notifications, setNotifications] = useState<any[]>();
  const notificationsContext = useContext(NotificationsContext);
  useEffect(() => {
    grabNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsContext]);

  const grabNotifications = () => {
    if (notificationsContext?.notifications) {
      setNotifications(notificationsContext?.notifications);
    }
  };
  useEffect(() => {
    getNotifications(
      '?sort=triggered_at,desc&size=102',
      (response: any) => {
        if (response.data.content.length > 0) {
          const contentFiltered = removeSomeNotifications(
            response.data.content
          );
          setNotifications(contentFiltered);
        } else {
          setNoNotifications(true);
        }
      },
      (error: any) => toast.error('Could not get notifications')
    );
  }, []);

  return (
    <TableContainer component={Paper} square={false} variant="outlined">
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Stack
                direction="row"
                spacing={2}
                justifyContent={'space-between'}
              >
                <Typography variant="h5">Your Notifications</Typography>

                {!noNotifications && (
                  <Button
                    className="card-link fw-normal text-covalo clickable"
                    onClick={
                      notificationsContext && notificationsContext.markAsRead()
                    }
                  >
                    Mark all as read
                  </Button>
                )}
              </Stack>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {noNotifications ? (
            <NoNotifications message="" />
          ) : (
            notifications?.map(notification => (
              <Notification
                {...notification}
                key={notification.key}
                identifier={notification.key}
              />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Notifications;
