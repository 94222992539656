import {
  SupplierPortalUserStatus,
  IS_UXBEAUTY,
  UserStateContextType
} from 'components/constants/Constants';
import { useUserState } from 'context/User';
import { isSupport } from 'helpers/Roles';
import { EventPermissionLevel, SupplierPortalPermissions } from 'portals/types';
import { UserState } from 'types/User';

/**
 * Convenience access to check if the user is verified, to avoid doing this check many times.
 */
export const useIfVerifiedUser = () => {
  const userState = useUserState();

  if (!IS_UXBEAUTY) return true; // this line is to make sure covalo still works
  if (userState.type === UserStateContextType.AUTHENTICATED) {
    if (isSupport(userState.user)) return true;
    if (
      userState.user.supplierPortalUserStatus ===
      SupplierPortalUserStatus.VERIFIED
    )
      return true;
    return false;
  } else {
    return false;
  }
};

export const useIsSupplierPortalProductEditorFieldDisabled = () => {
  const userState = useUserState();
  return isDisabled(userState);
};

export const useIsSupplierPortalUserEditorDisabled = () => {
  const userState = useUserState();
  if (userState.type !== UserStateContextType.AUTHENTICATED) return true;
  if (isSupport(userState.user)) return false;
  if (
    userState.user.supplierPortalUserPermissions?.[
      SupplierPortalPermissions.USER_MANAGEMENT
    ] !== EventPermissionLevel.MANAGE
  )
    return true;
  return false;
};

const isDisabled = (userState: UserState) => {
  if (!IS_UXBEAUTY) return false; // this line is to make sure covalo still works
  if (userState.type === UserStateContextType.AUTHENTICATED) {
    if (isSupport(userState.user)) return false;
    if (
      userState.user.supplierPortalUserPermissions?.[
        SupplierPortalPermissions.PRODUCT_MANAGEMENT
      ] === EventPermissionLevel.MANAGE
    )
      return false;
    return true;
  } else {
    return true;
  }
};

export const useCanSeeUserAccess = () => {
  const userState = useUserState();
  if (userState.type !== UserStateContextType.AUTHENTICATED) return false;
  if (isSupport(userState.user)) return true;
  return (
    userState.user.supplierPortalUserPermissions?.[
      SupplierPortalPermissions.USER_MANAGEMENT
    ] !== undefined
  );
};

export const useCanSeeProductEditor = () => {
  const userState = useUserState();
  if (userState.type !== UserStateContextType.AUTHENTICATED) return false;
  if (isSupport(userState.user)) return true;
  return (
    userState.user.supplierPortalUserPermissions?.[
      SupplierPortalPermissions.PRODUCT_MANAGEMENT
    ] !== undefined
  );
};
