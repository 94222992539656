import { tss } from 'tss-react/mui';

/**
 * Injects the CSS variables into the correct place in the dom.
 */
export const CssVariableOverrider: React.FC<React.PropsWithChildren> = ({
  children
}) => {
  const { classes } = useStyles();
  return <div className={classes.variableOverrides}>{children}</div>;
};

const useStyles = tss.create(({ theme }) => ({
  variableOverrides: {
    '--falcon-primary-hover': theme.palette.primary.dark,
    '--falcon-primary': theme.palette.primary.main,
    '--falcon-input-focus-border-color': theme.palette.primary.main,

    '& .btn-primary': {
      '--falcon-btn-border-color': theme.palette.primary.main,
      '--falcon-btn-hover-bg': theme.palette.primary.main,
      '--falcon-btn-hover-border-color': theme.palette.primary.main,
      '--falcon-btn-active-bg': theme.palette.primary.main,
      '--falcon-btn-active-border-color': theme.palette.primary.main,
      '--falcon-btn-disabled-border-color': theme.palette.primary.main,
      '--falcon-list-group-active-bg': theme.palette.primary.main,
      '--falcon-list-group-active-border-color': theme.palette.primary.main,
      '--falcon-btn-bg': theme.palette.primary.main,
      '--falcon-btn-disabled-bg': theme.palette.primary.main
    },

    '& .form-control:focus, & .form-select:focus': {
      boxShadow: `var(--falcon-box-shadow-inset),0 0 0 .25rem ${theme.palette.primary.main}40`
    },

    '& *::selection': {
      backgroundColor: theme.palette.primary.main,
      color: 'white'
    }
  }
}));
