import {
  getNotifications,
  readAllNotifications
} from 'api/services/NotificationsService';
import { removeSomeNotifications } from 'components/pages/Notifications/Notifications';
import { useInterval } from 'hooks/useInterval';
import { createContext, useState } from 'react';
import { toast } from 'react-toastify';

const NotificationsContext = createContext();
//TODO: move the state here like in this example https://kattya.dev/articles/2021-04-17-fixing-re-renders-when-using-context-in-react/
// and on all other contexts to be cleaner
const NotificationsProvider = ({ children }) => {
  const setMessages = messages => {
    setNotificationsContext({ ...notificationsContext, messages: messages });
  };

  const [notificationsContext, setNotificationsContext] = useState({
    refreshInterval: 120000, // slower update of the overall notifications of the website
    refreshIntervalOnPage: 20000, // for when the user is on a page where is nicer to have faster refreshed information
    messages: null,
    notifications: null,
    setMessages: setMessages,
    markAsRead: () => markAsReadAll,
    grabNotifications: () => fetchNotifications()
  });

  const markAsReadAll = () => {
    readAllNotifications(
      () => fetchNotifications(),
      error => toast.error(error)
    );
  };

  const fetchNotifications = () => {
    getNotifications(
      '?sort=triggered_at,desc&size=300',
      response => {
        if (response.data.content.length > 0) {
          const contentFiltered = removeSomeNotifications(
            response.data.content
          );
          const hasNewNotifications =
            JSON.stringify(notificationsContext.notifications) !==
            JSON.stringify(contentFiltered);
          if (hasNewNotifications) {
            const updated = {
              ...notificationsContext,
              notifications: contentFiltered
            };
            setNotificationsContext({ ...updated });
          }
        }
      }
      //   error => toast.error('Could not get notifications')
    );
  };

  useInterval(() => {
    // TODO: make here the api calls to simulate websockets and add or not dot to the icon depending if there is new notifications
    fetchNotifications();
  }, notificationsContext.refreshInterval);

  return (
    <NotificationsContext.Provider value={notificationsContext}>
      {children}
    </NotificationsContext.Provider>
  );
};

export { NotificationsContext, NotificationsProvider };
